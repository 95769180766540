<template>
    <div>
        <a-card>
            <a-page-header title='分组管理'>
                <a-button @click="$router.back()">返回</a-button>
            </a-page-header>
            <a-button style="margin: 0 0 20px 20px;" type="primary" @click="showModal = true">新建分组</a-button>
            <a-table bordered :loading="tableLoading" :columns="columns" :data-source="dataList"
                :row-key="(record, index) => index" :pagination='false'>
                <div slot="action" slot-scope="text, record">
                    <a-button type="link" style="margin-right: 15px;" @click="handleEdit(record)">修改</a-button>
                    <a-popconfirm placement="topRight" title="确定删除该分组?" ok-text="确定" cancel-text="取消"
                        @confirm="handleDel(record)">
                        <a-button v-show="record.boundAdvertiserCount === 0" type="link">删除</a-button>
                    </a-popconfirm>
                </div>
            </a-table>
            <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
                @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />
        </a-card>

        <a-modal v-model="showModal" title="新建分组" :footer="null" @cancel="handleCancel">
            <a-form-model style="margin-top: 30px;" ref="formRef" :model="formState" :rules="rules" :label-col="{ span: 5 }"
                :wrapper-col="{ span: 15 }">
                <a-form-model-item label="分组名称" prop="advertiserGroupName">
                    <a-input placeholder="请输入分组名称，最多输入20个字" :maxLength="20" v-model="formState.advertiserGroupName"
                        allowClear />
                </a-form-model-item>
            </a-form-model>
            <div class="tips">
                <div style="text-align: center;" v-show="showTip">已存在相同分组名称，请修改后再尝试</div>
            </div>
            <template>
                <a-row type="flex" justify="center">
                    <a-button key="submit" type="primary" style="width: 100px; margin-right: 20px;" :loading="submitLoading"
                        @click="handleOk">
                        提交
                    </a-button>
                    <a-button key="back" style="width: 100px" @click="handleCancel">
                        取消
                    </a-button>
                </a-row>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        const columns = [
            {
                align: 'center',
                title: '分组',
                dataIndex: 'advertiserGroupName',
                customRender(text) {
                    return text || '-'
                },
            },
            {
                align: 'center',
                title: '绑定广告主数',
                dataIndex: 'boundAdvertiserCount'
            },
            {
                align: 'center',
                title: '操作',
                key: 'action',
                width: 200,
                fixed: 'right',
                scopedSlots: { customRender: 'action' }
            }
        ]
        const rules = {
            advertiserGroupName: [
                { required: true, message: '请输入分组名称', trigger: 'change' },
                {
                    validator: (rule, value, callback) => {
                        if (!value || !value.trim()) {
                            return callback(new Error("请输入分组名称"))
                        }
                        callback()
                    }, trigger: 'blur'
                }
            ]
        }
        return {
            tableLoading: false,
            dataList: [],
            columns,
            rules,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            showModal: false,
            showTip: false,
            submitLoading: false,
            formState: {
                id: null,
                advertiserGroupName: null
            }
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        async getDataList() {
            this.tableLoading = true
            const params = {
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            const { code, data, message } = await this.$api.core.pphtManage.getAdvGroupManageList(params).finally(() => {
                this.tableLoading = false
            })
            if (code === 200) {
                this.dataList = data.list
                this.pagination.total = data.total || 0
            } else {
                console.error(`获取列表失败: ${message}`)
            }
        },
        handleEdit(data) {
            this.formState = {
                id: data.id,
                advertiserGroupName: data.advertiserGroupName
            }
            this.showModal = true
        },
        async handleDel(data) {
            const { code, message } = await this.$api.core.pphtManage.delAdvGroup(data.id)
            if (code === 200) {
                this.getDataList()
            } else {
                console.error(`删除失败: ${message}`)
            }
        },
        handleCancel() {
            this.formState.id = null
            this.formState.advertiserGroupName = null
            this.$refs.formRef.clearValidate();
            this.showTip = false
            this.showModal = false
        },
        handleOk() {
            this.$refs.formRef.validate(async valid => {
                if (!valid) return;
                this.submitLoading = true
                this.formState.advertiserGroupName = this.formState.advertiserGroupName.trim()
                const api = this.formState.id ? 'updateAdvGroup' : 'addAdvGroup'
                const { code, data } = await this.$api.core.pphtManage[api](this.formState).finally(() => {
                    this.submitLoading = false
                })
                if (code === 200) {
                    this.handleCancel()
                    this.pagination.current = 1
                    this.getDataList()
                } else if (code === 500) {
                    this.showTip = true
                    setTimeout(() => {
                        this.showTip = false
                    }, 2000);
                }
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        }
    }
}
</script>

<style scoped>
.tips {
    height: 30px;
    margin: 0 0 0 30px;
    color: #f5222d;
}
</style>